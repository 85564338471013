<template>
  <div class="w-full h-full">
    <div class="flex flex-col h-1/3 justify-end items-center mx-5">
      <div
        class="
          flex flex-row
          items-center
          justify-center
          max-w-5xl
          w-full
          relative
          mx-10
        "
      >
        <i class="fas fa-search absolute left-6"></i
        ><input
          class="
            px-16
            pr-24
            border-2
            bg-transparent
            border-gray-300
            w-full
            h-16
            rounded-lg
          "
          placeholder="Unesite šifru ili naziv artikla"
        />
        <a class="underline absolute right-6 cursor-pointer">Pretraži</a>
      </div>
      <div
        class="
          flex flex-row
          items-center
          justify-center
          max-w-5xl
          w-full
          relative
          mx-10
          gap-5
          mt-5
          underline
        "
      >
        <p>Samostojeći aparati</p>
        |
        <p>Ugradbeni aparati</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>